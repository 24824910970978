import schttp from 'public/src/services/schttp'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
const { lang } = gbCommonInfo

class Middleware {
  constructor() {
    this.langMap = {
      'ilen': {
        site: 'il',
        lang: 'www'
      },
      'cafr': {
        site: 'ca',
        lang: 'ca'
      }
    }
  }

  /**
   * 过滤推荐结果
   * @param {*} list 
   * @returns 
   */
  filterList(list) { 
    if (!list || !list.length) return []
    return list.filter((item) => Number(item.stock > 0) && Number(item.is_on_sale) === 1 && Number(item.is_del) === 0)
  }

  // 转换数据格式
  adapter({ type, data }) {
    if (type == 'emarsys') {
      data = data.map(item => {
        if (this.langMap[lang]) {
          let { lang: mapLang, site: mapSite } = this.langMap[lang]
          item.goods_url = item[`c_link_${lang}`] || item[`c_link_${mapLang}`] && item[`c_link_${mapLang}`].replace(`${mapLang}.shein.com`, `${mapSite}.shein.com`)
          item.goods_name = item[`c_title_${lang}`] || item[`c_title_${mapLang}`]
        } else {
          item.goods_url = item[`c_link_${lang == 'en' ? 'www' : lang}`]
          item.goods_name = item[`c_title_${lang == 'en' ? 'www' : lang}`] || item['c_title_www']
        }
        return {
          productRelationID: item.c_relation_id,
          goods_id: item.id,
          cat_id: item.c_catid,
          goods_url_name: item.goods_url_name || item.title,
          salePrice: {},
          retailPrice: {},
          unit_discount: 0,
          goods_name: item.goods_name,
          goods_img: item.image,
          goods_sn: item.c_sku,
          relatedColor: [],
          dataSource: 'emarsys'
        }
      })
    }
    if (type == 'own') {
      data = data.map(item => ({
        productRelationID: item.spu,
        goods_img: item.goodsImg,
        goods_id: item.goodsId,
        goods_sn: item.goodsSn,
        cat_id: item.catId,
        goods_url: getGoodsUrl(item.goodsUrlName, item.goodsId, item.catId),
        goods_name: item.goodsName,
        goods_url_name: item.goodsName,
        relatedColor: [],
        salePrice: item.salePrice || {},
        retailPrice: item.retailPrice || {},
        unit_discount: item.unit_discount || 0,
        dataSource: 'own'
      }))
    }
    return data
  }


  /**
   * 获取星级排行 评分
   * @param {*} items 
   * @returns 
   */
  async getRank (items) {
    if (!items || !items.length) return Promise.resolve(items)
    let spu_list = []
      
    items.forEach(item => {
      let spu = item.productRelationID
      if (!spu) return
      spu_list.push({ spu: spu })
    })
    if (spu_list.length <= 0) return Promise.resolve(items)

    let results = await schttp({
      method: 'POST',
      url: `/api/comment/abcCommentSummary/get`,
      data: { spu_list },
    })
    
    let commentInfos = {}
    if (results.code == 0 && results.info) {
      results.info.forEach(item => commentInfos[item.spu] = item)

      items.forEach(item => {
        let spu = item.productRelationID,
            comment = {}
        if (spu) comment = commentInfos[spu] || {}
        item.commentInfo = comment
      })
    }
    return Promise.resolve(items)
  }
  
  async getMutliColor(items) {
    if (!items || items.length <= 0) return Promise.resolve(items)
    let skuList = []
    let productsInfo = {}
    
    items.map(item => {
      skuList.push(item.goods_sn)
      productsInfo[item.goods_id] = item
    })

    let results = await schttp({
      method: 'POST',
      url: `/api/productInfo/relatedBySku/get?t=${Date.now()}`,
      data: { sku: skuList }
    })

    if (results.code == 0 && results.info) {
      results.info.products.reduce((acc, cur) => {
        if (productsInfo[cur.goods_id] && cur.relatedColor && cur.relatedColor.length) {
          productsInfo[cur.goods_id]['relatedColor'] = cur.relatedColor
          productsInfo[cur.goods_id]['relatedColorGoodsInfo'] = {}
          cur.relatedColor.forEach((item) => {
            productsInfo[cur.goods_id]['relatedColorGoodsInfo'][item.goods_id] = item
            acc.push(item)
          })
        }
        return acc
      }, [])

      items = Object.values(productsInfo)
    }
    return Promise.resolve(items)
  }

}

export default new Middleware()

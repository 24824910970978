/**
 * 获取 DOM 元素
 * 
 * @param {string} selector 
 * @returns {HTMLElement | null}
 */
export function getDOMNode(selector) {
  return document.querySelector(selector)
}

/**
 * 获取 DOM 节点列表
 * 
 * @param {string} selector 
 * @returns {HTMLElement[] | null}
 */
export function getDOMNodeList(selector) {
  return document.querySelectorAll(selector)
}

/**
 * 从 DOM 节点的 data-属性中获取数据
 * 
 * @param {string} selector 
 * @param {string} dataKeyName data-属性名
 * @returns 
 */
export function getFromNodeDataset(selector, dataKeyName) {
  const node = document.querySelector(selector)
  return node && node.dataset[dataKeyName]
}

/**
 * 从 DOM 节点的属性中获取数据
 * 
 * @param {string} selector 
 * @param {string} attr 属性名
 * @returns 
 */
export function getNodeAttribute(selector, attr) {
  const node = document.querySelector(selector)
  return node && node.getAttribute(attr)
}

/**
 * 当前页面是否存在指定的 DOM 元素
 * 
 * @param {string} selector 
 * @returns 
 */
export function hasNode(selector) {
  return !!document.querySelector(selector)
}

/**
 * DOM 节点是否包含指定的 class
 * 
 * @param {string} selector 
 * @param {string} className 
 */
export function hasClass(selector, className) {
  const node = document.querySelector(selector)
  return node && node.classList.contains(className)
}

/**
 * 为 DOM 节点添加、移除 class
 * 
 * @typedef {{
 *   remove?: string[],
 *   add?: string[],
 * }} ClassListOperation
 * 
 * @param {string | Element} selectorOrNode 
 * @param  {...ClassListOperation} operations 
 * @returns 
 */
export function changeNodeClassList(selectorOrNode, ...operations) {
  const node = typeof selectorOrNode === 'string' 
    ? document.querySelector(selectorOrNode)
    : selectorOrNode

  if (!node) return

  for (const operation of operations) {
    if (operation.remove) {
      node.classList.remove(...operation.remove)
    }
    if (operation.add) {
      node.classList.add(...operation.add)
    }
  }
}

/**
 * 等价于 JQuery 的 $(selector).css(prop)
 * 
 * 获取 DOM 节点的 CSS 属性
 * 
 * @param {string} selector 
 * @param {string} prop 
 * @returns 
 */
export function getNodeCSSProp(selector, prop) {
  const node = document.querySelector(selector)
  return node && getComputedStyle(node).getPropertyValue(prop)
}

/**
 * 等价于 JQuery 的 $(selector).css({ ... })
 * 
 * 设置 DOM 节点的 CSS 属性
 * 
 * @param {string} selector
 * @param {CSSStyleDeclaration} value
 */
export function setNodeCSSProp(selector, value) {
  const node = document.querySelector(selector)
  if (!node) return
  for (const [prop, val] of Object.entries(value)) {
    node.style.setProperty(prop, val)
  }
}

/**
 * 获取元素之前的所有兄弟节点
 * 
 * @param {HTMLElement} element 
 * @returns {HTMLElement[]}
 */
export function getPrevAll(element) {
  let prevSiblings = []
  while ((element = element.previousElementSibling)) {
    prevSiblings.push(element)
  }
  return prevSiblings
}

/**
 * 获取元素之前的所有兄弟节点，且满足指定的 class
 * 
 * @param {HTMLElement} element 
 * @param {string} selector 
 * @returns {HTMLElement[]}
 */
export function getPrevAllWithClass(element, selector) {
  let prevSiblings = []
  while ((element = element.previousElementSibling)) {
    if (element.matches(selector)) {
      prevSiblings.push(element)
    }
  }
  return prevSiblings
}

/**
 * 获取满足指定 selector 的祖先元素在其父元素中的索引
 *  
 * @param {HTMLElement} target 
 * @param {string} parentSelector 
 * @returns {number}
 */
export function getAncestorIndexOfParent(target, parentSelector) {
  let parentDiv = target.closest(parentSelector)
  if (!parentDiv) return -1 // 如果没有找到祖先 div，返回 -1

  let parent = parentDiv.parentElement
  if (!parent) return -1 // 如果祖先 div 没有父元素，返回 -1

  // 获取所有父元素的子元素列表
  let children = Array.from(parent.children)

  // 返回祖先在子元素列表中的索引
  return children.indexOf(parentDiv)
}



/**
 * 获取 DOM 节点的高度
 * 
 * @param {string} selector 
 * @returns {number} DOM 元素的高度
 */
export function getDOMNodeHeight(selector) {
  const node = document.querySelector(selector)
  return node && node.getBoundingClientRect().height
}
